import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from './../../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ArticleResponse, FileParameter } from './../../../../service/Client';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Type, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { get } from 'http';
import { set } from 'lodash';
import { Title } from '@angular/platform-browser';
import { ArticleEnum, Client } from 'app/service/Client';
import { constructor } from 'date-fns/add_weeks/index.js';
import * as ClassicEditorFull from '@novicov/ckeditor5-build-classic-full';
import { Subject } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';
import { FileManagerService } from 'app/admin/services/file-manager.service';

@Component({
    selector: 'app-news-site-item',
    templateUrl: './news-site-item.component.html',
    styleUrls: ['./news-site-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class NewsSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    article: ArticleResponse;

    stringKey = '';

    token: string;
    storageUrl = environment.storageUrl;
    optimizedEndpoint =  '/150x150/m=max';

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    public EditorFull = ClassicEditorFull;
    //public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    articleForm: FormGroup;
    title: FormControl;
    annonse: FormControl;
    description: FormControl;
    metaTitle: FormControl;
    metaKeywords: FormControl;
    metaDescription: FormControl;
    isActive: FormControl;
    isMain: FormControl;
    type: FormControl;
    selectedFile: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fileManager: FileManagerService
    ) {}

    ngOnInit(): void {
        this.route.params
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(params => {
            console.log(params);
            if (params.keyString) {
                this.isNew = false;
                this.stringKey = params.keyString;
                this.initArticle();
            } else {
                this.isNew = true;
                this.createFormControls();
            }
        });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
    
    initArticle(): void {
        this.client.article_Article(this.stringKey)
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(data => {
            this.article = data as ArticleResponse;
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getArticleError );
            console.error(error); });
    }

    createFormControls(): void {
        if (this.article) {
            this.title = new FormControl(this.article.title, Validators.required);
            this.annonse = new FormControl(this.article.annonse, Validators.required);
            this.description = new FormControl(this.article.description, Validators.required);
            this.metaTitle = new FormControl(this.article.metaTitle, Validators.required);
            this.metaKeywords = new FormControl(this.article.metaKeywords, Validators.required);
            this.metaDescription = new FormControl(this.article.metaDescription, Validators.required);
            this.isActive = new FormControl(this.article.isActive, Validators.required);
            this.isMain = new FormControl(this.article.isMain, Validators.required);
            this.type = new FormControl(this.article.type, Validators.required);
            this.selectedFile = new FormControl(this.article.image);
        } else {
            this.title = new FormControl(null, Validators.required);
            this.annonse = new FormControl(null, Validators.required);
            this.description = new FormControl(null, Validators.required);
            this.metaTitle = new FormControl(null, Validators.required);
            this.metaKeywords = new FormControl(null, Validators.required);
            this.metaDescription = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
            this.isMain = new FormControl(false, Validators.required);
            this.type = new FormControl(0, Validators.required);
            this.selectedFile = new FormControl(null);
        }
        this.articleForm = new FormGroup({
            Title: this.title,
            Annonse: this.annonse,
            Description: this.description,
            MetaTitle: this.metaTitle,
            MetaKeywords: this.metaKeywords,
            MetaDescription: this.metaDescription,
            IsActive: this.isActive,
            IsMain: this.isMain,
            Type: this.type,
            SelectedFile: this.selectedFile
        });
        this.isReady = true;
    }

    toPost(): void {
            this.client.adminArticle_AddOrUpdate(
                this.articleForm.value.Title, 
                this.articleForm.value.Annonse, 
                this.articleForm.value.Description,
                this.articleForm.value.MetaTitle,
                this.articleForm.value.MetaKeywords,
                this.articleForm.value.MetaDescription,
                this.articleForm.value.IsActive,
                this.articleForm.value.Type,
                this.articleForm.value.IsMain,
                this.stringKey,
                this.articleForm.value.SelectedFile
                ).pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                const response = data;
                console.log(response);
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.newsAddCompleate : notyf.message.newsUpdateCompleate );

                this.router.navigate(['/admin/news']); 
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.newsAddError : notyf.message.newsUpdateError );
                console.error(error); });
    }

    removeFile(): void {
        this.articleForm.controls['SelectedFile'].reset();
    }

    addPhoto(): void {
        this.fileManager.getToken().subscribe({
             next: (data) => {
                 console.log('data', data);
             },
             error: (e) => {
                 this.token = e.error.text;
                 this.openNewWindow();
             }
         })
     }
 
     openNewWindow(): void {
         let url = environment.storageUrlMain  + '/auth/' + this.token
 
         const windowFeatures = 'width=700,height=610,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
         
         const newWindow = window.open(url, 'File manager', windowFeatures);
 
         const messageListener = ({origin, data}) => {
             if (origin === environment.storageUrlMain) {
                 if (data.type === 'selectImage') {
                     data.data.forEach((element: any) => {
                        this.articleForm.controls['SelectedFile'].setValue(element.path);
                     });
                     newWindow.close();
 
                     window.removeEventListener('message', messageListener);
                 }
             }
         };
 
         window.addEventListener('message', messageListener);
     }

}
