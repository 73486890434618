import { notyf } from './../../../models/notyf';
import {MatPaginator, MatSnackBar} from '@angular/material';
import { environment } from './../../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import {ArticleResponse, FileParameter, AdminCategoryResponse, AdminCategoryParentHelper, CategoryProductRequest, PaginatorResponse} from './../../../../service/Client';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Type, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Client } from 'app/service/Client';
import * as ClassicEditorFull from '@novicov/ckeditor5-build-classic-full';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { FileManagerService } from 'app/admin/services/file-manager.service';

@Component({
    selector: 'app-category-item',
    templateUrl: './category-item.component.html',
    styleUrls: ['./category-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CategorySiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    category: AdminCategoryResponse;
    categoriesAll: AdminCategoryParentHelper[];
    categoriesAllDesplayed: AdminCategoryParentHelper[];
    token: string;
    storageUrl = environment.storageUrl;
    optimizedEndpoint = '/150x150/m=max'

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    public EditorFull = ClassicEditorFull;
    //public Editor = ClassicEditor;
    isNew = false;
    isReady = false;
    id = '';

    dataSource;

    displayedColumns: string[] = ['image', 'articul', 'name', 'brand', 'price', 'active'];


    filterRequest = {
        isReset: true,
        minPrice: 0,
        maxPrice: 0,
        currentMinPrice: 0,
        currentMaxPrice: 0,
        orderBy: 0
    };

    paginatorRequest = {
        countStart: 0,
        countFinish: 0,
        countTotal: 0,
        take: 10,
        page: 1,
        pageCount: 1,
        pageSelected: [1]
    };


    image = 'Файл не выбран';

    parentId = null;
    searchCategoryRequest = '';

    parentArray: FormArray;

    categoryForm: FormGroup;
    title: FormControl;
    id1C: FormControl;
    description: FormControl;
    fullDescription: FormControl;
    seoTitle: FormControl;
    seoDescription: FormControl;
    seoKeywords: FormControl;
    isActive: FormControl;
    isSale: FormControl;
    selectedFile: FormControl;

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder,
        private fileManager: FileManagerService 

    ) {
        this.route.params
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(params => {
            if (params.id) {
                this.isNew = false;
                this.id = params.id;
                this.initCategory();
                this.initProducts();
            } else {
                this.isNew = true;
                this.createFormControls();
            }
            this.initCategoryAll();
        });
    }

    ngOnInit(): void {

    }



    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initCategoryAll(): void {
        this.adminService.helperAllChangeStatus$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (data) {
                this.categoriesAll = data.categories;
                if (!this.isNew) {
                    const index = this.categoriesAll.findIndex(x => x.categoryId === this.id);
                    if (index > -1) {
                        this.categoriesAll.splice(index, 1);
                    }
                }
                this.categoriesAllDesplayed = this.categoriesAll;
            }
        });
    }

    initProducts(): void {
        let requestOpt;
        if (!this.dataSource) {
            requestOpt = {
                categoryId: this.id,
                filter: this.filterRequest,
                paginator: this.paginatorRequest
            };
        } else {
            this.paginatorRequest.page = this.paginator.pageIndex + 1;
            this.paginatorRequest.take = this.paginator.pageSize;

           requestOpt = {
                categoryId: this.id,
                filter: this.filterRequest,
                paginator: this.paginatorRequest
            };
        }

        this.client.product_GetCategoryProducts(requestOpt)
            .pipe( takeUntil(this.ngUnsubscribe) )
            .subscribe(data => {
                this.dataSource = data;
                this.paginatorRequest.countFinish = data.paginator.countFinish;
                this.paginatorRequest.countStart = data.paginator.countStart;
                this.paginatorRequest.countTotal = data.paginator.countTotal;
                this.paginatorRequest.page = data.paginator.page;
                this.paginatorRequest.pageCount = data.paginator.pageCount;
                this.paginatorRequest.pageSelected = data.paginator.pageSelected;
                this.paginatorRequest.take = data.paginator.take;
            }, error => {
                this.adminService.showNotify(notyf.type.error, notyf.message.getProductError );
                console.error(error); });
    }
    
    initCategory(): void {
        this.client.adminCategory_CategoryById(this.id)
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(data => {
            this.category = data as AdminCategoryResponse;
            // this.category.imageIcon = environment.apiUrlProd + environment.categoryImageUrl + this.category.id + '/' + this.category.imageIcon;
            this.image  = this.category.image;
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getCategoryError );
            console.error(error); });
    }

    createFormControls(): void {
        this.parentArray = new FormArray([]);
        if (this.category) {
            this.title = new FormControl(this.category.title, Validators.required);
            this.id1C = new FormControl(this.category.id1C, Validators.required);
            this.description = new FormControl(this.category.description);
            this.fullDescription = new FormControl(this.category.fullDescription);
            this.seoTitle = new FormControl(this.category.seoTitle);
            this.seoDescription = new FormControl(this.category.seoDescription);
            this.seoKeywords = new FormControl(this.category.seoKeywords);
            this.isActive = new FormControl(this.category.isActive, Validators.required);
            this.isSale = new FormControl(this.category.isSale, Validators.required);
            this.parentId = this.category.parentId;
            this.category.parents.map(x => this.parentArray.push(this.fb.group({ 
                categoryId: new FormControl(x.categoryId, Validators.required), 
                categoryTitle: new FormControl(x.categoryName, Validators.required)
            })));
            this.selectedFile = new FormControl(this.category.image);
        } else {
            this.title = new FormControl(null, Validators.required);
            this.id1C = new FormControl(null, Validators.required);
            this.description = new FormControl(null);
            this.fullDescription = new FormControl(null);
            this.seoTitle = new FormControl(null);
            this.seoDescription = new FormControl(null);
            this.seoKeywords = new FormControl(null);
            this.isActive = new FormControl(false, Validators.required);
            this.isSale = new FormControl(false, Validators.required);
            this.selectedFile = new FormControl(null);
        }
        this.categoryForm = new FormGroup({
            Title: this.title,
            Id1C: this.id1C,
            Description: this.description,
            FullDescription: this.fullDescription,
            SeoTitle: this.seoTitle,
            SeoDescription: this.seoDescription,
            SeoKeywords: this.seoKeywords,
            IsActive: this.isActive,
            IsSale: this.isSale,
            SelectedFile: this.selectedFile,
        });
        this.isReady = true;
    }

    toPost(): void {
        
        this.client.adminCategory_CategoryAddOrUpdate(
            this.id,
            this.categoryForm.value.Id1C,
            this.categoryForm.value.Title,
            this.categoryForm.value.Description,
            this.categoryForm.value.FullDescription,
            this.categoryForm.value.SeoTitle,
            this.categoryForm.value.SeoDescription,
            this.categoryForm.value.SeoKeywords,
            this.parentId,
            '',
            this.categoryForm.value.IsSale,
            this.categoryForm.value.IsActive,
            this.categoryForm.value.SelectedFile
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            const response = data;
            const index = this.adminService.helperAll.categories.findIndex(x => x.categoryId === response.id);
            if (index > -1) {
                this.adminService.helperAll.categories[index].categoryName = response.title;
                this.adminService.helperAllChangeStatusSource.next(this.adminService.helperAll);
            }
            this.router.navigate(['/admin/categories']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.categoryAddCompleate : notyf.message.categoryUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.categoryAddError : notyf.message.categoryUpdateError );
            console.error(error); });
   }

   fileChanged(event: any) {
    this.selectedFile = event;
    }

    searchCategory(): void {
        if (!this.searchCategoryRequest) {
            this.categoriesAllDesplayed = this.categoriesAll;
            return;
        }
        this.categoriesAllDesplayed = this.categoriesAll.filter(x => x.categoryName.toLocaleLowerCase().includes(this.searchCategoryRequest.toLocaleLowerCase()));
    }
    removeFile(): void {
        this.categoryForm.controls['SelectedFile'].setValue(null);
    }

    addPhoto(): void {
       this.fileManager.getToken().subscribe({
            next: (data) => {
                console.log('data', data);
            },
            error: (e) => {
                this.token = e.error.text;
                this.openNewWindow();
            }
        })
    }

    openNewWindow(): void {
        let url = environment.storageUrlMain  + '/auth/' + this.token

        const windowFeatures = 'width=700,height=610,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
        
        const newWindow = window.open(url, 'File manager', windowFeatures);

        const messageListener = ({origin, data}) => {
            if (origin === environment.storageUrlMain) {
                if (data.type === 'selectImage') {
                    data.data.forEach((element: any) => {
                        this.categoryForm.controls['SelectedFile'].setValue(element.path);
                    });
                    newWindow.close();

                    window.removeEventListener('message', messageListener);
                }
            }
        };

        window.addEventListener('message', messageListener);
    }
}
