import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { Client, ArticleResponse, BlogRequest, SliderResponse, SliderItemResponse } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class SliderSiteService implements Resolve<any>
{
    slider: SliderResponse;
    sliderItems: SliderItemResponse[];
    
    onSliderChanged: BehaviorSubject<any>;
    onSliderItemsChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onSliderChanged = new BehaviorSubject({});
        this.onSliderItemsChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getSliders()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getSliders(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminSlider_GetMainSlider()
                .subscribe((response: any) => {
                    this.slider = response;
                    // this.slider.items.map(x => x.image = environment.storageUrl + x.image);
                    this.sliderItems = this.slider.items;
                    this.onSliderChanged.next(this.slider);
                    this.onSliderItemsChanged.next(this.sliderItems);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getSliderError );
                });
        });
    }

    changePosition(index: number, type: string): Promise<any> {

        const prom = this.sliderItems[index];
        const fisrtId = this.sliderItems[index].id;
        let secondId = 0;
        if (type === 'up') {
            secondId = this.sliderItems[index - 1].id;
            this.sliderItems[index] = this.sliderItems[index - 1];
            this.sliderItems[index - 1] = prom;

            const position = this.sliderItems[index - 1].position;
            this.sliderItems[index - 1].position = this.sliderItems[index].position;
            this.sliderItems[index].position = position;
        } else {
            secondId = this.sliderItems[index + 1].id;
            this.sliderItems[index] = this.sliderItems[index + 1];
            this.sliderItems[index + 1] = prom;

            const position = this.sliderItems[index + 1].position;
            this.sliderItems[index + 1].position = this.sliderItems[index].position;
            this.sliderItems[index].position = position;
        }
        this.slider.items = this.sliderItems;

        return new Promise((resolve, reject) => {
            this.client.adminSlider_ChangePosition(fisrtId, secondId)
                .subscribe((response: any) => {
                    this.onSliderChanged.next(this.slider);
                    this.onSliderItemsChanged.next(this.sliderItems);
                    resolve(response);
                    this.adminService.showNotify(notyf.type.success, notyf.message.postionChangeCompleate );
                }, reject => { 
                    this.adminService.showNotify(notyf.type.error, notyf.message.postionChangeError );});
        });           
    }
    deleteContact(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminSlider_RemoveItemById(id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }


    public sliderLength(): number {
        return this.sliderItems ? this.sliderItems.length : 0;
    }
}
