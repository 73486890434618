import { AdminBaseService } from './../../services/admin.base.service';
import { Client, ArticleResponse, BlogRequest } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class NewsSiteSerivce implements Resolve<any>
{
    articles: ArticleResponse[];
    onArticleChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    ) {
        // Set the defaults
        this.onArticleChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getArticles()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    deleteContact(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminArticle_RemoveItemById(id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getArticles(): Promise<any> {
        return new Promise((resolve, reject) => {
            const model: BlogRequest = {
                take: 100,
                skip: 0,
                isActive: false,
                all: true
            };
            this.client.article_Articles(model)
                .subscribe((response: any) => {
                    this.articles = response;
                    // this.articles.map(x => x.image = environment.apiUrlProd + environment.blogMain + x.id + '/' + x.image);
                    console.log(this.articles);
                    this.onArticleChanged.next(this.articles);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getArticleError);
                });
        });
    }
}
