import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminPartnerResponse } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { ArticleResponse, FileParameter, Client } from 'app/service/Client';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { notyf } from 'app/admin/models/notyf';
import { FileManagerService } from 'app/admin/services/file-manager.service';

@Component({
  selector: 'app-partners-site-item',
  templateUrl: './partners-site-item.component.html',
  styleUrls: ['./partners-site-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
  
})
export class PartnersSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    partner: AdminPartnerResponse;

    token: string;
    storageUrl: string = environment.storageUrl;
    optimizedEndpoint = '/150x150/m=max';

    id = 0;

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    
    partnerForm: FormGroup;
    isActive: FormControl;
    name: FormControl;
    description: FormControl;
    url: FormControl;
    country: FormControl;
    id1c: FormControl;
    selectedFile: FormControl;
    
    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fileManager: FileManagerService

    ) {
        this.route.params
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(params => {
            console.log(params);
            if (params.id) {
                this.isNew = false;
                this.id = params.id;
                this.initPartner();
            } else {
                this.isNew = true;
                this.createFormControls();
            }
        });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
    
    initPartner(): void {
        this.client.adminPartner_PartnerById(this.id)
        .pipe( takeUntil(this.ngUnsubscribe) )
        .subscribe(data => {
            this.partner = data as AdminPartnerResponse;
            // this.partner.image = environment.apiUrlProd + environment.partners + this.partner.id + '/' + this.partner.image;

            console.log(this.partner);            
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getPartnerError );
            console.error(error); });
    }

    createFormControls(): void {
        if (this.partner) {
            this.isActive = new FormControl(this.partner.isActive, Validators.required);
            this.name = new FormControl(this.partner.name, Validators.required);
            this.description = new FormControl(this.partner.description, Validators.required);
            this.url = new FormControl(this.partner.url, Validators.required);
            this.country = new FormControl(this.partner.country, Validators.required);
            this.id1c = new FormControl(this.partner.id1C, Validators.required);
            this.selectedFile = new FormControl(this.partner.image);
        } else {
            this.isActive = new FormControl(false, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.description = new FormControl(null, Validators.required);
            this.url = new FormControl(null, Validators.required);
            this.country = new FormControl(null, Validators.required);
            this.id1c = new FormControl(null, Validators.required);
            this.selectedFile = new FormControl(null);
        }
        this.partnerForm = new FormGroup({
            IsActive: this.isActive,
            Name: this.name,
            Description: this.description,
            Url: this.url,
            Country: this.country,
            Id1C: this.id1c,
            SelectedFile: this.selectedFile
        });
        this.isReady = true;
    }

    toPost(): void {
            this.client.adminPartner_PartnerUpdate(
                this.id,
                this.partnerForm.value.Id1C,
                this.partnerForm.value.IsActive,
                this.partnerForm.value.Name,
                this.partnerForm.value.Description,
                this.partnerForm.value.Url,
                this.partnerForm.value.Country,
                this.partnerForm.value.SelectedFile                
            ).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const response = data;
                console.log(response);
                this.router.navigate(['/admin/partners']);
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.partnerAddCompleate : notyf.message.partnerUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.partnerAddError : notyf.message.partnerUpdateError );
                console.error(error); });
    }
    
    // fileChanged(event: any) {
    //     this.selectedFile = event;
    //     }


    removeFile(): void {
        this.partnerForm.controls['SelectedFile'].reset();
    }

    addPhoto(): void {
        this.fileManager.getToken().subscribe({
             next: (data) => {
                 console.log('data', data);
             },
             error: (e) => {
                 this.token = e.error.text;
                 this.openNewWindow();
             }
         })
     }
 
     openNewWindow(): void {
         let url = environment.storageUrlMain  + '/auth/' + this.token
 
         const windowFeatures = 'width=700,height=610,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
         
         const newWindow = window.open(url, 'File manager', windowFeatures);
 
         const messageListener = ({origin, data}) => {
             if (origin === environment.storageUrlMain) {
                 if (data.type === 'selectImage') {
                     data.data.forEach((element: any) => {
                        this.partnerForm.controls['SelectedFile'].setValue(element.path);
                     });
                     newWindow.close();
 
                     window.removeEventListener('message', messageListener);
                 }
             }
         };
 
         window.addEventListener('message', messageListener);
     }
}
