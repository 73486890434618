import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminCategoryParentHelper, AdminStoreResponse, AdminOptionResponse, AdminOptionValueResponse, AdminUnitResponse, StoreProductCountAddOrUpdate, AdminProductAddOrUpdate, AdminProductPriceAddOrUpdate } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminProductResponse, FileParameter, Client, ArticleResponse, AdminBrandResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { environment } from 'environments/environment';
import { result } from 'lodash';
import { notyf } from 'app/admin/models/notyf';
import { FileManagerService } from 'app/admin/services/file-manager.service';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ProductSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    token: string;
    storageUrl = environment.storageUrl;
    product: AdminProductResponse;

    id = '';

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;

    minDate = null;
    maxDate = null;

    storeAll: AdminStoreResponse[] = [];
    storeAllDisplayed: AdminStoreResponse[] = [];
    storeSearch = '';
    selectedStoreId = null;

    categoryAll: AdminCategoryParentHelper[] = [];
    categoryAllDisplayed: AdminCategoryParentHelper[] = [];
    categorySearch = '';
    selectedOptionId = null;

    optionAll: AdminOptionResponse[] = [];
    optionAllDisplayed: AdminOptionResponse[] = [];
    optionSearch = '';
    selectedCategoryId = null;

    optionValueAll: AdminOptionValueResponse[] = [];
    optionValueAllDisplayed: AdminOptionValueResponse[] = [];
    optionValueSearch = '';
    selectedOptionValueId = null;

    unitAll: AdminUnitResponse[] = [];
    unitAllDisplayed: AdminUnitResponse[] = [];
    unitSearch = '';
    selectedUnitId = null;

    brandAll: AdminBrandResponse[] = [];
    brandAllDisplayed: AdminBrandResponse[] = [];
    brandSearch = '';
    selectedBrandId = null;

    productForm: FormGroup;
    id1c: FormControl;
    title: FormControl;
    description: FormControl;
    labelIsShowMain: FormControl;
    labelIsActive: FormControl;
    labelIsNew: FormControl;
    labelIsDiscount: FormControl;
    labelIsBestseller: FormControl;
    articul: FormControl;
    seoTitle: FormControl;
    seoDescription: FormControl;
    seoKeywords: FormControl;
    crossNumber: FormControl;

    descriptionFrom: FormGroup;
    fullDescription: FormControl;

    storeArray: FormArray;
    categoryArray: FormArray;
    optionArray: FormArray;
    imageArray: FormArray;

    priceForm: FormGroup;
    discountStart: FormControl;
    discountEnd: FormControl;
    rublePrice: FormControl;
    rublePriceDiscount: FormControl;
    dollarPrice: FormControl;
    dollarPriceDiscount: FormControl;

    imageNewForm: FormGroup;

    id1cImage: FormControl;
    isMain: FormControl;
    newImageFile: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder,
        private fileManager: FileManagerService

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initProduct();
                } else {
                    this.isNew = true;
                    this.createFormControls();
                }
            });
    }

    ngOnInit(): void {
        this.adminService.helperAllChangeStatus$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (data) {
                this.optionAll = data.options;
                this.storeAll = data.stores;
                this.categoryAll = data.categories;
                this.unitAll = data.units;
                this.brandAll = data.brands;

                this.optionAllDisplayed = data.options;
                this.storeAllDisplayed = data.stores;
                this.categoryAllDisplayed = data.categories;
                this.unitAllDisplayed = data.units;
                this.brandAllDisplayed = data.brands;

                if (this.selectedOptionId) {
                    const index = this.optionAll.findIndex(x => x.id === this.selectedOptionId);
                    if (index > -1) {
                        this.optionValueAll = this.optionAll[index].values;
                    }
                }
            }
        }, error => { console.error(error); });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initProduct(): void {
        this.client.adminProduct_ProductById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.product = data as AdminProductResponse;
                console.log(this.product);
                this.id = this.product.id;
                console.log(data);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getProductError );
                console.error(error); });
    }

    initImagePath(image: string): string {
        return image ? environment.storageUrl + image : null;
    }

    dateChanged(type: number): void {
        let date = new Date();
        if (type === 0) {
            this.minDate = this.priceForm.value.DiscountStart.toDate();
        } else {
            this.maxDate = this.priceForm.value.DiscountEnd.toDate();
        }
    }
    
    createFormControls(): void {
        this.storeArray = new FormArray([]);
        this.categoryArray = new FormArray([]);
        this.optionArray = new FormArray([]);        
        this.imageArray = new FormArray([]);        
        if (this.product) {
            this.id1c = new FormControl(this.product.id1C, Validators.required);
            this.title = new FormControl(this.product.title, Validators.required);
            this.description = new FormControl(this.product.description);            
            this.labelIsShowMain = new FormControl(this.product.isShowMain);
            this.labelIsActive = new FormControl(this.product.isActive);
            this.labelIsNew = new FormControl(this.product.isNew);
            this.labelIsDiscount = new FormControl(this.product.isDiscount);
            this.labelIsBestseller = new FormControl(this.product.isBestseller);
            this.articul = new FormControl(this.product.articul, Validators.required);
            this.seoTitle = new FormControl(this.product.seoTitle);
            this.seoDescription = new FormControl(this.product.seoDescription);
            this.seoKeywords = new FormControl(this.product.seoKeywords);
            this.crossNumber = new FormControl(this.product.crossNumber);

            this.fullDescription = new FormControl(this.product.fullDescription);

            this.minDate = this.product.productPrice.discountStart;
            this.maxDate = this.product.productPrice.discountEnd;

            this.selectedBrandId = this.product.brandId;
            this.selectedUnitId = this.product.unitId;

            this.product.storeProductCount.map(x => this.storeArray.push(this.fb.group({ 
                id: new FormControl(x.id),
                count: new FormControl(x.count),
                storeId: new FormControl(x.storeId),
                delivery: new FormControl(x.delivery),
                storeTitle: new FormControl(x.storeTitle),
             })));

            this.product.categoryProducts.map(x => this.categoryArray.push(this.fb.group({ 
                productId: new FormControl(x.productId),
                productTitle: new FormControl(x.productTitle),
                categoryId: new FormControl(x.categoryId),
                categoryTitle: new FormControl(x.categoryTitle),
             })));

            this.product.optionValueProducts.map(x => this.optionArray.push(this.fb.group({ 
                productId: new FormControl(x.productId),
                optionValuesId: new FormControl(x.optionValuesId),
                productTitle: new FormControl(x.productTitle),
                optionValueTitle: new FormControl(x.optionValueTitle),
                optionId: new FormControl(x.optionId),
                optionTitle: new FormControl(x.optionTitle)
             })));

            this.product.images.map(x => this.imageArray.push(this.fb.group({ 
                id: new FormControl(x.id),
                id1c: new FormControl(x.id1C),
                productId: new FormControl(x.productId),
                isMain: new FormControl(x.isMain),
                imageIcon: new FormControl(this.initImagePath(x.originalUrl))
             })));

             this.discountStart = new FormControl(this.product.productPrice.discountStart);
             this.discountEnd = new FormControl(this.product.productPrice.discountEnd);
             this.rublePrice = new FormControl(this.product.productPrice.rublePrice, Validators.required);
             this.rublePriceDiscount = new FormControl(this.product.productPrice.rublePriceDiscount, Validators.required);
             this.dollarPrice = new FormControl(this.product.productPrice.dollarPrice, Validators.required);
             this.dollarPriceDiscount = new FormControl(this.product.productPrice.dollarPriceDiscount, Validators.required);

        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.title = new FormControl(null, Validators.required);
            this.description = new FormControl(null);            
            this.labelIsShowMain = new FormControl(false);
            this.labelIsActive = new FormControl(false);
            this.labelIsNew = new FormControl(false);
            this.labelIsDiscount = new FormControl(false);
            this.labelIsBestseller = new FormControl(false);
            this.articul = new FormControl(null, Validators.required);
            this.seoTitle = new FormControl(null);
            this.seoDescription = new FormControl(null);
            this.seoKeywords = new FormControl(null);
            this.crossNumber = new FormControl(null);

            this.fullDescription = new FormControl(null);

             this.discountStart = new FormControl(null);
             this.discountEnd = new FormControl(null);
             this.rublePrice = new FormControl(0, Validators.required);
             this.rublePriceDiscount = new FormControl(0, Validators.required);
             this.dollarPrice = new FormControl(0, Validators.required);
             this.dollarPriceDiscount = new FormControl(0, Validators.required);
        }


        this.id1cImage = new FormControl(null, Validators.required);
        this.isMain = new FormControl(false);
        this.newImageFile = new FormControl(null, Validators.required);

        this.imageNewForm = new FormGroup({
            Id1cImage: this.id1cImage,
            IsMain: this.isMain,
            NewImageFile: this.newImageFile
        });

        this.productForm = new FormGroup({
            Id1c: this.id1c,
            Title: this.title,
            Description: this.description,            
            LabelIsShowMain: this.labelIsShowMain,
            LabelIsActive: this.labelIsActive,
            LabelIsNew: this.labelIsNew,
            LabelIsDiscount: this.labelIsDiscount,
            LabelIsBestseller: this.labelIsBestseller,
            Articul: this.articul,
            SeoTitle: this.seoTitle,
            SeoDescription: this.seoDescription,
            SeoKeywords: this.seoKeywords,
            CrossNumber: this.crossNumber
        });

        this.priceForm = new FormGroup({
            DiscountStart: this.discountStart,
            DiscountEnd: this.discountEnd,
            RublePrice: this.rublePrice,
            RublePriceDiscount: this.rublePriceDiscount,
            DollarPrice: this.dollarPrice,
            DollarPriceDiscount: this.dollarPriceDiscount
        });

        this.descriptionFrom = new FormGroup({
            FullDescription: this.fullDescription
        });

        this.isReady = true;
    }

    // addPhone(): void {
    //     this.phoneArray.push(this.fb.group({ phone: new FormControl(null) }));
    // }

    // removePhone(index: number): void {
    //     this.phoneArray.controls.splice(index, 1);
    // }
    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    searchStores(): void {
        if (!this.storeSearch) {
            this.storeAllDisplayed = this.storeAll;
            return;
        }
        this.storeAllDisplayed = this.storeAll.filter(x => x.name.toLocaleLowerCase().includes(this.storeSearch.toLocaleLowerCase()));
    }

    searchCategory(): void {
        if (!this.categorySearch) {
            this.categoryAllDisplayed = this.categoryAll;
            return;
        }
        this.categoryAllDisplayed = this.categoryAll.filter(x => x.categoryName.toLocaleLowerCase().includes(this.categorySearch.toLocaleLowerCase()));
    }

    searchUnit(): void {
        if (!this.unitSearch) {
            this.unitAllDisplayed = this.unitAll;
            return;
        }
        this.unitAllDisplayed = this.unitAll.filter(x => x.name.toLocaleLowerCase().includes(this.unitSearch.toLocaleLowerCase()));
    }

    searchBrand(): void {
        if (!this.brandSearch) {
            this.brandAllDisplayed = this.brandAll;
            return;
        }
        this.brandAllDisplayed = this.brandAll.filter(x => x.name.toLocaleLowerCase().includes(this.brandSearch.toLocaleLowerCase()));
    }

    searchOption(): void {
        if (!this.optionSearch) {
            this.optionAllDisplayed = this.optionAll;
            return;
        }
        this.optionAllDisplayed = this.optionAll.filter(x => x.name.toLocaleLowerCase().includes(this.optionSearch.toLocaleLowerCase()));
    }

    searchOptionValue(): void {
        if (!this.optionValueSearch) {
            this.optionValueAllDisplayed = this.optionValueAll;
            return;
        }
        this.optionValueAllDisplayed = this.optionValueAll.filter(x => x.value.toLocaleLowerCase().includes(this.optionValueSearch.toLocaleLowerCase()) && x.optionId === this.selectedOptionId);
    }

    changeOption(): void {
        const index = this.optionAll.findIndex(x => x.id === this.selectedOptionId);
        if (index > -1) {
            this.optionValueAllDisplayed = this.optionAll[index].values;
            this.optionValueSearch = '';
        }
    }

    changeOptionValue(): void {
        console.log(this.selectedOptionValueId);
    }

    removeStoreCount(index: number): void {
        this.storeArray.controls.splice(index, 1);
        this.storeArray.value.splice(index, 1);
    }

    removeCategory(index: number): void {
        this.categoryArray.controls.splice(index, 1);
        this.categoryArray.value.splice(index, 1);
    }

    removeOptionValue(index: number): void {
        this.optionArray.controls.splice(index, 1);
        this.optionArray.value.splice(index, 1);
    }

    addStoreCount(): void {
        const index = this.storeAll.findIndex(x => x.id === this.selectedStoreId);
        const exist = this.storeArray.value.findIndex(x => x.storeId === this.selectedStoreId);
        if (index < 0 || exist > -1) {
            return;
        }
        this.storeArray.push(this.fb.group({ 
            id: new FormControl(null),
            count: new FormControl(0),
            delivery: new FormControl(null),
            storeId: new FormControl(this.storeAll[index].id),
            storeTitle: new FormControl(this.storeAll[index].name),
         }));
    }

    getProductId(): any {
        return this.isNew ? null : this.product.id;
    }

    getProductTitle(): any {
        return this.isNew ? null : this.product.title;
    }

    addOptionValue(): void {
        const indexOption = this.optionAll.findIndex(x => x.id === this.selectedOptionId);
        const exist = this.optionArray.value.findIndex(x => x.categoryId === this.selectedCategoryId);
        if (indexOption === -1 || exist > -1) {
            return;
        }
        const indexValue = this.optionAll[indexOption].values.findIndex(x => x.id === this.selectedOptionValueId);
        if (indexValue === -1) {
            return;
        }
        this.optionArray.push(this.fb.group({ 
            productId: new FormControl(this.id),
            optionValuesId: new FormControl(this.selectedOptionValueId),
            productTitle: new FormControl(this.isNew ? '' : this.product.title),
            optionValueTitle: new FormControl(this.optionAll[indexOption].values[indexValue].value),
            optionId: new FormControl(this.selectedOptionId),
            optionTitle: new FormControl((this.optionAll[indexOption].name))
        }));
    }

    addCategory(): void {
        const index = this.categoryAll.findIndex(x => x.categoryId === this.selectedCategoryId);
        const exist = this.categoryArray.value.findIndex(x => x.categoryId === this.selectedCategoryId);
        if (index < 0 || exist > -1) {
            return;
        }
        this.categoryArray.push(this.fb.group({ 
            productId: new FormControl(this.getProductId()),
            productTitle: new FormControl(this.getProductTitle()),
            categoryId: new FormControl(this.categoryAll[index].categoryId),
            categoryTitle: new FormControl(this.categoryAll[index].categoryName),
         }));
    }

    addImage(): void {
        this.client.adminProduct_AddProductImage(
            this.id,
            this.imageNewForm.value.Id1cImage,
            this.imageNewForm.value.NewImageFile,
            this.imageNewForm.value.IsMain)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.product.images.unshift(data);
                
                this.imageArray.push(this.fb.group({ 
                    id: new FormControl(data.id),
                    id1c: new FormControl(data.id1C),
                    isMain: new FormControl(data.isMain),
                    productId: new FormControl(data.productId),
                    imageIcon: new FormControl(this.initImagePath(data.originalUrl))
                 }));
                 this.imageNewForm.reset();
                 this.adminService.showNotify(notyf.type.success, notyf.message.addImageCompleate );
                }, error => { 
                    this.adminService.showNotify(notyf.type.error, notyf.message.addImageError );
                    console.error(error); 
                });
    }

    removeImage(index: number): void {
        if (this.imageArray.value[index]) {
            this.client.adminProduct_RemoveProductImage(this.imageArray.value[index].id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                    this.imageArray.controls.splice(index, 1);
                    this.imageArray.value.splice(index, 1);
                    if (data) {
                        const index = this.imageArray.value.findIndex(x => x.id === data) 
                            if (index > -1) {
                                const control = <FormArray>this.imageArray;
                                <FormArray>control.controls[index]['controls'].isMain.setValue(true);
                            }
                        }
                        this.adminService.showNotify(notyf.type.success, notyf.message.removeImageCompleate );
                    }, error => { 
                        this.adminService.showNotify(notyf.type.error, notyf.message.removeImageError );
                        console.error(error); });
        }
    }

    changeMainPhoto(index: number): void {
        if (!this.imageArray) {
            return;
        }
        const control = <FormArray>this.imageArray;
        const mainImage = this.imageArray.value[index];
        for (let i = 0; i < this.imageArray.controls.length; i++) {
            <FormArray>control.controls[i]['controls'].isMain.setValue(i === index);
        }
        this.client.adminProduct_UpdateMainProductImage(mainImage.id, mainImage.productId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            console.log('Image main update');
            this.adminService.showNotify(notyf.type.success, notyf.message.changeMainImageCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.changeMainImageError );
            console.error(error); });

    }

    toPost(): void {
        const storeCountModel: StoreProductCountAddOrUpdate[] = [];
        for (let i = 0; i < this.storeArray.value.length; i++) {
            let model: StoreProductCountAddOrUpdate = {
                storeId: this.storeArray.value[i].storeId,
                delivery: this.storeArray.value[i].delivery,
                count: this.storeArray.value[i].count
            };
            storeCountModel.push(model);
        }
        const prodcutOptionValueModel: string[] = [];
        for (let i = 0; i < this.optionArray.value.length; i++) {
            prodcutOptionValueModel.push(this.optionArray.value[i].optionValuesId);
        }
        const categoryProductModel: string[] = [];
        for (let i = 0; i < this.categoryArray.value.length; i++) {
            categoryProductModel.push(this.categoryArray.value[i].categoryId);
        }
        const productPriceModel: AdminProductPriceAddOrUpdate = {
            discountStart: this.priceForm.value.DiscountStart,
            discountEnd: this.priceForm.value.DiscountEnd,   
            rublePrice: this.priceForm.value.RublePrice,
            rublePriceDiscount: this.priceForm.value.RublePriceDiscount,
            dollarPrice: this.priceForm.value.DollarPrice,
            dollarPriceDiscount: this.priceForm.value.DollarPriceDiscount
        };
        const mainModel: AdminProductAddOrUpdate = {          
            id: this.id,
            id1C: this.productForm.value.Id1c,
            title: this.productForm.value.Title,
            description: this.productForm.value.Description,
            fullDescription: this.descriptionFrom.value.FullDescription,
            isShowMain: this.productForm.value.LabelIsShowMain,
            isActive: this.productForm.value.LabelIsActive,
            isNew: this.productForm.value.LabelIsNew,
            isDiscount: this.productForm.value.LabelIsDiscount,
            isBestseller: this.productForm.value.LabelIsBestseller,
            articul: this.productForm.value.Articul,
            seoTitle: this.productForm.value.SeoTitle,
            seoDescription: this.productForm.value.SeoDescription,
            seoKeywords: this.productForm.value.SeoKeywords,
            brandId: this.selectedBrandId,
            crossNumber: this.productForm.value.CrossNumber,    
            unitId: this.selectedUnitId,
            storeProductCount: storeCountModel,        
            categoryIds: categoryProductModel,
            optionValueProductIds: prodcutOptionValueModel,    
            productPrice: productPriceModel
        };

        this.client.adminProduct_AddOrUpdateProduct(mainModel)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            const response = data;
            this.router.navigate(['/admin/products']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.productAddCompleate : notyf.message.productUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.productAddError : notyf.message.productUpdateError );
            console.error(error); });



        // this.client.adminProduct_ProductAddOrUpdate(model)
        // .pipe(takeUntil(this.ngUnsubscribe))
        // .subscribe(data => {
        //     this.router.navigate(['/admin/products']);
        // }, error => { console.error(error); })
    }

    removeFile(): void {
        this.imageNewForm.controls['NewImageFile'].setValue(null);
    }

    addPhoto(): void {
       this.fileManager.getToken().subscribe({
            next: (data) => {
                console.log('data', data);
            },
            error: (e) => {
                this.token = e.error.text;
                this.openNewWindow();
            }
        })
    }

    openNewWindow(): void {
        let url = environment.storageUrlMain  + '/auth/' + this.token

        const windowFeatures = 'width=700,height=610,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
        
        const newWindow = window.open(url, 'File manager', windowFeatures);

        const messageListener = ({origin, data}) => {
            if (origin === environment.storageUrlMain) {
                if (data.type === 'selectImage') {
                    data.data.forEach((element: any) => {
                        this.imageNewForm.controls['NewImageFile'].setValue(element.path);
                    });
                    newWindow.close();

                    window.removeEventListener('message', messageListener);
                }
            }
        };

        window.addEventListener('message', messageListener);
    }
}