import { AdminBaseService } from './../../services/admin.base.service';
import { environment } from './../../../../environments/environment.hmr';
import { Client, AdminCategoryResponse, AdminCategoryRequest, AdminCategoryHelper } from './../../../service/Client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class CategorySiteService implements Resolve<any> {
    categories: AdminCategoryResponse[];
    categoriesHelper: AdminCategoryHelper;
    onCategoryChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
        ) {
        // Set the defaults
        this.onCategoryChanged = new BehaviorSubject({});
    }
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const model: AdminCategoryRequest = {
            pageFilter: this.adminService.getDefaultPageFilter('')
        };
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCategories(model)
            ]).then(() => {
                resolve();
            }, reject);
        });
    }
    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getCategories(model: AdminCategoryRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.client.adminCategory_Categories(model)
                .subscribe((response: any) => {
                    this.categoriesHelper = response;
                    this.categories = this.categoriesHelper.categories;
                    if (this.categories && this.categories.length > 0) { 
                        // this.categories.map(x => x.image = x.image ? environment.apiUrlProd + environment.categoryImageUrl + x.id + '/' + x.image : null);
                    }
                    this.onCategoryChanged.next(this.categories);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getCategoryError );
                });
        });
    }

    // changePosition(index: number, type: string): Promise<any> {

    //     const prom = this.categories[index];
    //     const fisrtId = this.categories[index].id;
    //     let secondId = '';
    //     if (type === 'up') {
    //         secondId = this.categories[index - 1].id;
    //         this.categories[index] = this.categories[index - 1];
    //         this.categories[index - 1] = prom;
    //     } else {
    //         secondId = this.categories[index + 1].id;
    //         this.categories[index] = this.categories[index + 1];
    //         this.categories[index + 1] = prom;
    //     }
    //     this.categoriesHelper.categories = this.categories;

    //     return new Promise((resolve, reject) => {
    //         this.client.adminCategory_ChangePosition(fisrtId, secondId)
    //             .subscribe((response: any) => {
    //                 this.onCategoryChanged.next(this.categories);
    //                 resolve(response);
    //             }, reject);
    //     });           
    // }

    public categoryLength(): number {
        return this.categoriesHelper && this.categoriesHelper.paginator ? this.categoriesHelper.paginator.countTotal : 0;
    }
}
