import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { NewsSiteSerivce } from './news-site.service';
import { Client } from 'app/service/Client';
import { ArticleResponse } from './../../../service/Client';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';

import { takeUntil } from 'rxjs/internal/operators';
import { environment } from 'environments/environment';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-news-site',
  templateUrl: './news-site.component.html',
  styleUrls: ['./news-site.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class NewsSiteComponent implements OnInit
{
    dataSource: FilesDataSource | null;
    displayedColumns = ['id', 'image', 'name', 'category', 'created', 'updated', 'active','buttons'];

    storageUrl = environment.storageUrl;
    optimizedEndpoint =  '/150x150/m=max';
    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    @ViewChild(MatSort)
    sort: MatSort;

    @ViewChild('filter')
    filter: ElementRef;
    contacts: any;
    dialogRef: any;
    confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private client: Client,
        private newsSiteService: NewsSiteSerivce,
        private adminBaseService: AdminBaseService,
        public _matDialog: MatDialog
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.adminBaseService.resetPage = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.dataSource = new FilesDataSource(this.newsSiteService, this.paginator, this.sort, this.adminBaseService);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if ( !this.dataSource )
                {
                    return;
                }
                this.adminBaseService.resetPage = true;  
                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }
    deleteContact(id): void
    {
        this.confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Вы уверены, что хотите удалить?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this.newsSiteService.deleteContact(id).then(x =>{
                    let index: number = this.newsSiteService.articles.findIndex(d => d.id === id);
                    this.newsSiteService.articles.splice(index,1);
                    this.newsSiteService.onArticleChanged.next(this.newsSiteService.articles);
                    this.ngOnInit();
                });
            }
            this.confirmDialogRef = null;
        });

    }
}

export class FilesDataSource extends DataSource<any>
{
    private _filterChange = new BehaviorSubject('');
    private _filteredDataChange = new BehaviorSubject('');

    /**
     * Constructor
     *
     * @param {NewsSiteSerivce} newsSiteService
     * @param {MatPaginator} _matPaginator
     * @param {MatSort} _matSort
     */
    constructor(
        private newsSiteService: NewsSiteSerivce,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
        private _adminBaseService: AdminBaseService
    )
    {
        super();

        this.filteredData = this.newsSiteService.articles;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]>
    {
        const displayDataChanges = [
            this.newsSiteService.onArticleChanged,
            this._matPaginator.page,
            this._filterChange,
            this._matSort.sortChange
        ];

        return merge(...displayDataChanges)
            .pipe(
                map(() => {
                        let data = this.newsSiteService.articles.slice();
                        if (this._adminBaseService.resetPage) {
                            this._matPaginator._pageIndex = 0;
                        }

                        data = this.filterData(data);

                        this.filteredData = [...data];

                        data = this.sortData(data);

                        // Grab the page's slice of data.
                        const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                        return data.splice(startIndex, this._matPaginator.pageSize);
                    }
                ));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Filtered data
    get filteredData(): any
    {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any)
    {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string
    {
        return this._filterChange.value;
    }

    set filter(filter: string)
    {
        this._filterChange.next(filter);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data): any
    {
        if ( !this.filter )
        {
            return data;
        }
        data.map(x => {
            x.annonse = '';
            x.description = '';
            x.metaDescription = '';
            x.metaKeywords = '';
            x.metaTitle = '';
        });

        return FuseUtils.filterArrayByString(data, this.filter);
    }

    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data): any[]
    {
        if ( !this._matSort.active || this._matSort.direction === '' )
        {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';

            switch ( this._matSort.active )
            {
                case 'id':
                    [propertyA, propertyB] = [a.id, b.id];
                    break;
                case 'name':
                    [propertyA, propertyB] = [a.title, b.title];
                    break;
                case 'categories':
                    [propertyA, propertyB] = [a.type, b.type];
                    break;
                case 'created':
                    [propertyA, propertyB] = [a.created, b.created];
                    break;
                case 'updated':
                    [propertyA, propertyB] = [a.updated, b.updated];
                    break;
                case 'active':
                    [propertyA, propertyB] = [a.isActive, b.isActive];
                    break;
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });

    }
   
    /**
     * Disconnect
     */
    disconnect(): void
    {
    }
}
