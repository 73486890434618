import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminDownloadInfoResponse } from './../../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AdminDownloadInfoItemResponse, FileParameter, Client, ArticleResponse, DownloadInfoItemResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import * as ClassicEditor from '@novicov/ckeditor5-build-classic-full';
import { Subject } from 'rxjs';
import { notyf } from 'app/admin/models/notyf';
import { FileManagerService } from 'app/admin/services/file-manager.service';

@Component({
  selector: 'app-downloadinfo-item',
  templateUrl: './downloadinfo-item.component.html',
  styleUrls: ['./downloadinfo-item.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DownloadInfoItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    downloadInfoItem: AdminDownloadInfoItemResponse;
    downloadInfo: AdminDownloadInfoResponse[];

    optimizedEndpoint =  '/150x150/m=max'
    storageUrl = environment.storageUrl;
    token: string;

    stringKey = '';
    id = null;

    noPhoto: string =  environment.apiUrlProd + environment.noPhotoUrl;

    public Editor = ClassicEditor;
    isNew = false;
    isReady = false;
    
    backgroundImage: FormControl;
    file: FormControl;
    fileSize: FormControl;

    downloadInfoItemForm: FormGroup;
    title: FormControl;
    isActive: FormControl;
    downloadInfoId: FormControl;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private adminService: AdminBaseService,
        private client: Client,
        private fileManager: FileManagerService

    ) {}

    ngOnInit(): void {
        this.route.params
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(params => {
            console.log(params);
            if (params.stringKey) {
                this.isNew = false;
                this.stringKey = params.stringKey;
                this.initDownloadInfoItem();
            } else {
                this.isNew = true;
                this.client.adminDownloadInfo_DownloadInfoCategory()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    this.downloadInfo = data as AdminDownloadInfoResponse[];   
                    this.createFormControls();
                }, error => { 
                    this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                    console.error(error); });
            }
        });
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initDownloadInfoItem(): void {
        this.client.adminDownloadInfo_DownloadInfoItemStringKey(this.stringKey)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.downloadInfoItem = data as AdminDownloadInfoItemResponse;
                this.id = this.downloadInfoItem.id;
                this.client.adminDownloadInfo_DownloadInfoCategory()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    this.downloadInfo = data as AdminDownloadInfoResponse[];   
                    console.log(this.downloadInfo);
                     
                    this.createFormControls();
                }, error => { 
                    this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                    console.error(error); });
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                console.error(error); });
    }

    createFormControls(): void {
        if (this.downloadInfoItem) {
            this.title = new FormControl(this.downloadInfoItem.title, Validators.required);
            this.isActive = new FormControl(this.downloadInfoItem.isActive, Validators.required);
            this.downloadInfoId = new FormControl(this.downloadInfoItem.downloadInfoId, Validators.required);
            this.fileSize = new FormControl(this.downloadInfoItem.fileSize, Validators.required);
            this.file = new FormControl(this.downloadInfoItem.file, Validators.required);
            this.backgroundImage = new FormControl(this.downloadInfoItem.image);
        } else {
            this.title = new FormControl(null, Validators.required);
            this.isActive = new FormControl(false, Validators.required);
            this.downloadInfoId = new FormControl(null, Validators.required);
            this.file = new FormControl(null, Validators.required);
            this.fileSize = new FormControl(null, Validators.required);
            this.backgroundImage = new FormControl(null);
        }
        
        this.downloadInfoItemForm = new FormGroup({
            Title: this.title,
            IsActive: this.isActive,
            BackgroundImage: this.backgroundImage,
            File: this.file,
            FileSize: this.fileSize,
            DownloadInfoId: this.downloadInfoId
        });
        this.isReady = true;
    }

    toPost(): void {
        this.client.adminDownloadInfo_DownloadInfoItemUpdate(
            this.id,
            this.downloadInfoItemForm.value.Title,
            this.downloadInfoItemForm.value.File,
            this.downloadInfoItemForm.value.FileSize,
            this.downloadInfoItemForm.value.BackgroundImage,
            this.downloadInfoItemForm.value.IsActive,
            this.downloadInfoItemForm.value.DownloadInfoId
        ).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                const response = data;
                console.log(response);
                this.router.navigate(['/admin/downloadfile']);
                this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.downloadInfoAddCompleate : notyf.message.downloadInfoUpdateCompleate );
            }, error => { 
                this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.downloadInfoAddError : notyf.message.downloadInfoUpdateError );
                console.error(error); });
    }

    removeFile(controlName: string): void {
        if(controlName === 'File') {
            this.downloadInfoItemForm.controls['FileSize'].reset();
        }
        this.downloadInfoItemForm.controls[controlName].setValue(null);
    }

    addPhoto(controlName: string): void {
       this.fileManager.getToken().subscribe({
            next: (data) => {
                console.log('data', data);
            },
            error: (e) => {
                this.token = e.error.text;
                this.openNewWindow(controlName);
            }
        })
    }

    openNewWindow(controlName: string): void {
        let url = environment.storageUrlMain  + '/auth/' + this.token

        const windowFeatures = 'width=700,height=610,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
        
        const newWindow = window.open(url, 'File manager', windowFeatures);

        const messageListener = ({origin, data}) => {
            if (origin === environment.storageUrlMain) {
                if (data.type === 'selectImage') {
                    data.data.forEach((element: any) => {
                        this.downloadInfoItemForm.controls[controlName].setValue(element.path);
                        console.log('element', element);
                        
                        if (controlName === 'File') {
                            this.downloadInfoItemForm.controls['FileSize'].setValue(this.bytesToMegabytes(element.sizeInBytes));
                        }
                    });
                    newWindow.close();

                    window.removeEventListener('message', messageListener);
                }
            }
        };

        window.addEventListener('message', messageListener);
    }

    bytesToMegabytes(bytes: number): number {
        const megabytes = bytes / (1024 * 1024);
        return megabytes < 0.01 ? parseFloat(megabytes.toFixed(3)) : parseFloat(megabytes.toFixed(2));
    }
}


