import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { AdminCountryResponse, StoreTypeEnum } from './../../../../service/Client';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { AdminStoreResponse, FileParameter, Client, ArticleResponse, StoreResponse } from 'app/service/Client';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { notyf } from 'app/admin/models/notyf';
import * as ClassicEditorFull from '@novicov/ckeditor5-build-classic-full';
import { FileManagerService } from 'app/admin/services/file-manager.service';

@Component({
    selector: 'app-store-item',
    templateUrl: './store-item.component.html',
    styleUrls: ['./store-item.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class StoreSiteItemComponent implements OnInit, OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    store: AdminStoreResponse;
    countries: AdminCountryResponse[];
    token: string;

    id = 0;
    image: string;
    storageUrl: string = environment.storageUrl;
    newImage: FormControl = new FormControl(null);

    public EditorFull = ClassicEditorFull;
    isNew = false;
    isReady = false;

    storeType = 0;
    cityId = 0;

    phoneArray: FormArray;

    storeForm: FormGroup;
    id1c: FormControl;
    name: FormControl;
    adress: FormControl;
    latitude: FormControl;
    longtitude: FormControl;
    description: FormControl;
    isActive: FormControl;
    showInFooter: FormControl;
    phone: FormControl;
    frame: FormControl;
    descriptionHtml: FormControl;

    constructor(
        private route: ActivatedRoute,
        private adminService: AdminBaseService,
        private router: Router,
        private client: Client,
        private fb: FormBuilder,
        private fileManager: FileManagerService

    ) {
        this.route.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                console.log(params);
                if (params.id) {
                    this.isNew = false;
                    this.id = params.id;
                    this.initStore();
                } else {
                    this.isNew = true;
                    this.initCountries();
                }
            });
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }

    initCountries(): void {
        this.client.adminSetting_CountriesAll()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.countries = data;
            this.createFormControls();
        }, error => { 
            this.adminService.showNotify(notyf.type.error, notyf.message.getCountryError );
            console.error(error); });
    }

    initStore(): void {
        this.client.adminStore_StoreById(this.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.store = data.store;
                this.countries = data.countries;
                this.id = this.store.id;
                this.newImage.setValue(data.store.image);
                this.createFormControls();
            }, error => { 
                this.adminService.showNotify(notyf.type.error, notyf.message.getStoreError );
                console.error(error); });
    }

    createFormControls(): void {
        this.phoneArray = new FormArray([]);
        if (this.store) {
            this.id1c = new FormControl(this.store.id1C, Validators.required);
            this.name = new FormControl(this.store.name, Validators.required);
            this.adress = new FormControl(this.store.adress, Validators.required);
            this.latitude = new FormControl(this.store.latitude);
            this.longtitude = new FormControl(this.store.longtitude);
            this.description = new FormControl(this.store.description);
            this.isActive = new FormControl(this.store.isActive, Validators.required);
            this.showInFooter = new FormControl(this.store.showInFooter, Validators.required);
            this.phone = new FormControl(null);
            this.frame = new FormControl(this.store.frame);
            this.descriptionHtml = new FormControl(this.store.descriptionHtml);
            this.storeType = this.store.type;
            this.cityId = this.store.cityId;
            this.store.phones.map(x => this.phoneArray.push(this.fb.group({ phone: new FormControl(x) })));
        } else {
            this.id1c = new FormControl(null, Validators.required);
            this.name = new FormControl(null, Validators.required);
            this.adress = new FormControl(null, Validators.required);
            this.latitude = new FormControl(null);
            this.longtitude = new FormControl(null);
            this.description = new FormControl(null);
            this.frame = new FormControl(null);
            this.descriptionHtml = new FormControl(null);
            this.isActive = new FormControl(false, Validators.required);
            this.showInFooter = new FormControl(false, Validators.required);
            this.phone = new FormControl(null);
            this.cityId = this.countries && this.countries[0].cities ? this.countries[0].cities[0].id : 0;
        }
        this.storeForm = new FormGroup({
            Id1c: this.id1c,
            Name: this.name,
            Adress: this.adress,
            Latitude: this.latitude,
            Longtitude: this.longtitude,
            Description: this.description,
            IsActive: this.isActive,
            ShowInFooter: this.showInFooter,
            Phone: this.phone,
            Frame: this.frame,
            DescriptionHtml: this.description
        });

        this.isReady = true;
    }
    fileChanged(event): void
    {
        this.newImage=event;
    }

    addPhone(): void {
        this.phoneArray.push(this.fb.group({ phone: new FormControl(null) }));
    }

    removePhone(index: number): void {
        this.phoneArray.controls.splice(index, 1);
        this.phoneArray.value.splice(index, 1);
    }

    getBtnText(): string {
        return this.isNew ? 'Сохранить' : 'Обновить';
    }

    toPost(): void {
        if (this.phoneArray.controls && this.phoneArray.controls.length > 0) {
            this.initPhoneControl();
        } else {
            this.saveStore();
        }
    }

    cityChanged(event: any): void {
        this.cityId = event.value;
    }

    saveStore(): void {
        this.client.adminStore_StoreAddOrUpdate(
            this.id,
            this.storeForm.value.Id1c,
            this.storeForm.value.Name,
            this.storeForm.value.Adress,
            this.storeForm.value.Latitude,
            this.newImage.value,
            this.storeForm.value.Longtitude,
            this.storeForm.value.Description,
            this.storeForm.value.IsActive,
            this.storeForm.value.ShowInFooter,
            this.storeType,
            this.storeForm.value.Phone,
            this.cityId,
            this.storeForm.value.Frame,
            this.storeForm.value.DescriptionHtml)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.router.navigate(['/admin/stores']);
            this.adminService.showNotify(notyf.type.success, this.isNew ? notyf.message.storeAddCompleate : notyf.message.storeUpdateCompleate );
        }, error => { 
            this.adminService.showNotify(notyf.type.error, this.isNew ? notyf.message.storeAddError : notyf.message.storeUpdateError );
            console.error(error); });
    }

    initPhoneControl(): void {
        let result = '';
        for (let i = 0; i < this.phoneArray.controls.length; i++) {
            if (i === this.phoneArray.controls.length - 1) {
                result += this.phoneArray.value[i].phone;
            } else {
                result += `${this.phoneArray.value[i].phone},`;
            }
        }
        this.storeForm.controls['Phone'].setValue(result);
        this.saveStore();
    }

    // on1FileChange(event: any) {
    //     let fi = event.srcElement;
    //     if (fi.files && fi.files[0]) {
    //         const model: FileParameter = {
    //             data: fi.files[0],
    //             fileName: fi.files[0].name
    //         };
    //         this.selectedFile = model;
    //     }
    // }

    removeFile(): void {
        this.newImage.reset();
    }

    addPhoto(): void {
        this.fileManager.getToken().subscribe({
             next: (data) => {
                 console.log('data', data);
             },
             error: (e) => {
                 this.token = e.error.text;
                 this.openNewWindow();
             }
         })
     }
 
     openNewWindow(): void {
         let url = environment.storageUrlMain  + '/auth/' + this.token
 
         const windowFeatures = 'width=700,height=610,resizable=yes,scrollbars=yes,status=yes,titlebar=yes,toolbar=yes,menubar=no,location=no';
         
         const newWindow = window.open(url, 'File manager', windowFeatures);
 
         const messageListener = ({origin, data}) => {
             if (origin === environment.storageUrlMain) {
                 if (data.type === 'selectImage') {
                     data.data.forEach((element: any) => {
                         this.newImage.setValue(element.path);
                     });
                     newWindow.close();
 
                     window.removeEventListener('message', messageListener);
                 }
             }
         };
 
         window.addEventListener('message', messageListener);
     }

}