import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminBaseService } from 'app/admin/services/admin.base.service';
import { environment } from 'environments/environment';
import { AdminDownloadInfoItemHelper, AdminDownloadInfoItemResponse, Client, AdminDownloadInfoItemRequest } from 'app/service/Client';
import { notyf } from 'app/admin/models/notyf';

@Injectable()
export class DownloadInfoFileService implements Resolve<any>
{
    public downloadInfoItemHelper: AdminDownloadInfoItemHelper;
    downloadInfoItem: AdminDownloadInfoItemResponse[];
    onDownloadInfoItemChanged: BehaviorSubject<any>;
    onDownloadInfoItemHelperChanged: BehaviorSubject<any>;

    constructor(
        private client: Client,
        private adminService: AdminBaseService
    )
    {
        // Set the defaults
        this.onDownloadInfoItemChanged = new BehaviorSubject({});
        this.onDownloadInfoItemHelperChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @param {AdminDownloadInfoItemRequest} model
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        const model: AdminDownloadInfoItemRequest = {
            pageFilter: this.adminService.getDefaultPageFilter(undefined),
        };
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDownloadInfoItem(model)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getDownloadInfoItem(model: AdminDownloadInfoItemRequest): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.client.adminDownloadInfo_DownloadInfoItem(model)
                .subscribe((response: any) => {
                    this.downloadInfoItemHelper = response;
                    if (this.downloadInfoItemHelper && this.downloadInfoItemHelper.downloadInfoItems) {
                        this.downloadInfoItemHelper.downloadInfoItems.map(x => {
                            x.image = x.image ? environment.storageUrl + x.image : null;
                            x.file = x.file ? environment.storageUrl + x.file : null;
                        });
                    }
                    this.downloadInfoItem = this.downloadInfoItemHelper.downloadInfoItems;
                    this.onDownloadInfoItemChanged.next(this.downloadInfoItem);
                    this.onDownloadInfoItemHelperChanged.next(this.downloadInfoItemHelper);
                    resolve(response);
                }, reject => {
                    this.adminService.showNotify(notyf.type.error, notyf.message.getDownloadInfoError );
                });
        });
    }
}
